section.main-body {
    .intro-text-widget {
        .title-widget {
            margin-bottom: 20px;
            &.contrast-color{
                color: $requiredColor!important;
            }
        }
    }
}
.intro-text-widget {
    border-bottom: 1px solid #d9d9d9;
    padding: 0 0 32px;
    &.no-line{
        border-bottom: none;
        padding-bottom: 0;
    }
}

.dashboard-widget {
    margin-top: 40px;
    margin-bottom: 40px;
    h5 {
        font-size: 140%;
        color: $primaryColour;
    }
    ul {
        padding: 0;
        list-style-type: none;
        margin: 40px 0 0;
        & > li {
            .item {
                margin: 0 0 23px;
            }
            &:last-child {
                .item {
                    margin: 0;
                }
            }
        }
    }
    .img-cont {
        border-radius: 50%;
        overflow: hidden;
        min-height: 79px;
        min-width: 79px;
        margin: 0 30px 0 0;
        background-image: url('../img/img-holder.png');
        @include breakpoint('sm-xs') {
            display: none;
        }
    }
    .text-cont {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1;
        .text-row {
            width: 100%;
            margin: 0 0 15px;
        }
        .text-col {
            width: 47%;
            padding-right: 25px;
            @include breakpoint('sm-xs') {
                width: 100%;
                padding-right: 10px;
            }
            .status-text {
                @include breakpoint('xs-sml') {
                    display: block;
                }
            }
        }
        p {
            font-size: 17px;
            font-family: "Light";
            @include breakpoint('sm-xs') {
                font-size: 15px;
            }
        }
        .name {
            font-size: 22px;
            color: $inprogressColour;
            font-family:"Bold";
            @include breakpoint('sm-xs') {
                font-size: 18px;
            }
        }
        .position {
            font-family:"Bold";
        }
        .status-text {
            color: $inprogressColour;
            font-family:"Bold";
        }
    }
    .message-icon {
        position: absolute;
        left: -25px;
        top: calc(50% - 30px);
        background: $messageColour;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint('sm-xs'){
            position: relative;
            left: 0;
            margin: 0 5px 0 0;
            height: 50px;
            width: 50px;
        }
        & > i {
            font-size: 20px;
            margin: 0 5px 0 0;
            @include breakpoint('sm-xs'){
                margin: 0;
                font-size: 15px;
            }
        }
    }
    .digit {
        margin: 0 5px 0 0;
        font-size: 22px;
        @include breakpoint('sm-xs'){
            font-size: 18px;
        }
    }
    .plus {
        position: absolute;
        top: 12px;
        left: 19px;
        vertical-align: text-top;
        display: inline-block;
        line-height: 1;
        font-size: 14px;
        font-weight: 400;
        @include breakpoint('sm-xs'){
            top: 8px;
        }
    }
    .text-btn {
        font-size: 32px;
        color: #8c8b8b;
        & > i {
            transition: all linear .3s;
        }
        &:hover {
            & > i {
                padding-left: 6px;
            }
            & > i{
                transform: translateX(5px);
            }
        }
        .icon-text {
            font-size: 18px;
            text-transform: none;
        }
    }

    & + .pagenumber-container {
        border-top: 1px solid #d9d9d9;
        padding: 40px 0 40px;
        .page-numbers {
            text-decoration: none;
        }
    }
    .d-preloader {
        position: absolute;
        top: 0;
        bottom: 0;
        background: rgba( 255, 255, 255, 0.1 );
        width: 100%;
        z-index: 9;
    }
}
.custom-pagination{

    .pagenumber-container{
        display: flex;
        @media screen and (max-width: 500px){
            flex-direction: column-reverse!important;
        }

        ul.page-numbers{
            float: none;
            margin: 0 0 0 15px;
            a{
                cursor: pointer;
                text-decoration: none;
            }
        }
    }
    &__right{
        .pagenumber-container{
            justify-content: flex-start;
            flex-direction: row-reverse;
            align-items: center;
        }
    }
}

section.main-body {
    .dashboard-widget {
        ul {
            .item {
                position: relative;
                display: flex;
                align-items: center;
                background-color: $baseGrey;
                padding: 29px 43px 28px 59px;
                border-bottom: 10px solid #dadada;
                text-decoration: none;

                @include breakpoint('sm-xs'){
                    padding: 29px 25px 28px 25px;
                }


                &:hover {
                    .text-btn {
                        color: $inprogressColour;
                    }
                    &.successful-dashboard {
                        .text-btn {
                            color: $textOnPrimaryColour;
                            span{
                                color: $textOnPrimaryColour;
                            }
                        }
                    }
                    &.complete-dashboard {
                        .text-btn {
                            color: $textOnPrimaryColour;
                            span{
                                color: $textOnPrimaryColour;
                            }
                        }
                    }
                    &.in-progress-dashboard,
                    &.rejected-dashboard,
                    &.closed-dashboard {
                        .text-btn {
                            color: $inprogressColour;
                            span{
                                color: $inprogressColour;
                            }
                        }
                    }
                    &.in-review-dashboard,
                    &.pending-dashboard {
                        .text-btn {
                            color: $inreviewColour;
                            span{
                                color: $inreviewColour;
                            }
                        }
                    }
                }
                &.successful-dashboard {
                    background-color: $completeColour;
                    .name,
                    .status-text,
                    .text-btn {
                        color: $textOnPrimaryColour;
                    }
                }
                &.complete-dashboard {
                    background-color: $completeColour;
                    .name,
                    .status-text,
                    .text-btn {
                        color: $textOnPrimaryColour;
                        span {
                            color: $textOnPrimaryColour;
                        }
                    }
                }
                &.in-progress-dashboard,
                &.rejected-dashboard,
                &.closed-dashboard {
                    .name,
                    .status-text {
                        color: $inprogressColour;
                    }
                }
                &.in-review-dashboard,
                &.pending-dashboard {
                    .name,
                    .status-text {
                        color: $inreviewColour;
                    }
                }
            }
        }
    }
}

.progress-devider {
    border-bottom: 10px solid;
    position: absolute;
    left: 0;
    bottom: -10px;
    .in-progress-dashboard &,
    .closed-dashboard &,
    .rejected-dashboard & {
        border-color: $inprogressColour;
    }
    .successful-dashboard & {
        border-color: $completeBorderColour;
    }
    .complete-dashboard & {
        border-color: $completeBorderColour;
    }
    .in-review-dashboard &,
    .pending-dashboard &{
        border-color: $inreviewColour;
    }
}


.select-blc, .date-blc {
    display: flex;
    @include breakpoint('sm-xs'){
        flex-direction: column;
    }
}

.search-blc{
    border-bottom: 10px solid #dadada;
    margin-bottom: 26px;

    input[type="text"]{
        padding: 0 99px 0 31px !important;
        color: #969696!important;
        font-size: 17px!important;
        line-height: 24px!important;
        @include breakpoint('sm-xs'){
            padding: 0 65px 0 13px !important;
        }
    }
    .submit-btn{
        i{
            padding: 0 41px!important;
            @include breakpoint('sm-xs'){
                padding: 0 25px !important;
            }
        }
    }
}
.select-blc {
    margin: 0 -12px 26px;
    @include breakpoint('sm-xs'){
        flex-direction: column;
        margin: 0 0 26px 0;
    }
    .selectpicker {

    }
    .bootstrap-select{
        flex: 1;
        width: 100%!important;
        margin: 0 12px!important;
        @include breakpoint('sm-xs'){
            margin: 0 0 15px!important;
        }
        .dropdown-toggle{
            background: #eeeeee!important;
            border-bottom: none!important;
            height: 41px !important;
            line-height: 45px !important;
            padding: 0 13px !important;
            color: #595959!important;
            font-size: 16px!important;
            font-weight: 300!important;
            overflow: hidden;
            .caret{
                border-top: 6px solid!important;
                border-right: 6px solid transparent!important;
                border-left: 6px solid transparent!important;
                border-bottom: none!important;
            }
        }

        &.open{
            .caret{
                transform: rotate(180deg);
            }
        }
    }

    .dropdown-menu{
        margin: 0;
        border-radius: 0!important;
        background: #efefef !important;
        border-color: transparent!important;
        box-shadow: none;
        overflow-x: auto;
        height: 190px;
        width: 100%;
        li{
            &.selected{
                a{
                    background: #dadada!important;
                }
            }
            a{
                display: block!important;
                padding: 8px 10px;
                white-space: inherit!important;
                &:hover {
                    background: #dadada!important;
                    text-decoration: underline!important;
                }
            }
        }
        .text{
            color: #595959;
            font-size: 16px;
        }
    }
}
.z-validation{
    //border-bottom: none!important;

    border-bottom: 1px solid #d9d9d9!important;
    padding: 0 0 32px;
}
section.main-body{
    .date-blc {
        h4.date-blc_title {
            font-size: 18px !important;
        }
    }
}
.date-blc {
    flex-wrap: wrap;
    margin: 0 -15px;
    h4.date-blc_title {
        flex: 0 0 100%;
        font-size: 18px!important;
        margin: 0 15px 13px!important;
        @include breakpoint('sm-xs'){
            flex: 1;
        }
    }
    & > div{
        @include breakpoint('sm-xs'){
            margin: 0 0 15px!important;
        }
        input[type="text"]{
            padding: 0px 15px!important;
            color: #595959;
            font-size: 16px;
        }
        .mobile-placeholder{
            display: none;
            //@include breakpoint('xs'){
            //    display: block;
            //    color: #595959;
            //    font-size: 16px!important;
            //    font-weight: 300!important;
            //    padding: 8px 15px;
            //    margin-top: -41px;
            //    margin-bottom: 0;
            //    height: 41px;
            //}
        }
    }
    input[type="date"]{
        width: 100%!important;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .datepicker{
        height: 41px!important;
        font-size: 16px!important;
        font-weight: 300!important;
        line-height: 22.02px!important;
        color: #595959!important;
        border: none;
        @include breakpoint('xs'){
            width: 100%;
            background: #eeeeee !important;
            padding: 0 15px;
        }
    }
    .date-from{
        flex: 1;
        padding: 0 13px !important;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            z-index: 2;
            margin-top: -2px;
            right: 23px;
            vertical-align: middle;
            color: #595959 !important;
            border-top: 6px solid !important;
            border-right: 6px solid transparent !important;
            border-left: 6px solid transparent !important;
        }
    }
    .date-to{
        flex: 1;
        padding: 0 13px !important;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            z-index: 2;
            margin-top: -2px;
            right: 23px;
            vertical-align: middle;
            color: #595959 !important;
            border-top: 6px solid !important;
            border-right: 6px solid transparent !important;
            border-left: 6px solid transparent !important;
        }
    }
}



.reset-blc{

    text-align: right;
    .reset-btn{
        margin-top: 40px;
        color: #757273;
        font-size: 17px;
        text-decoration: underline;
    }
}

.items-list{
    margin-bottom: 40px!important;
}

.upload-btn{
    cursor: pointer;
    font-weight: 500;
    .text-row{
       display: flex;
        align-items: center;
        font-weight: 500;
        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .type-file{
            color: #333333;
            font-size: 16px;
            margin-left: 10px;
            @media screen and (max-width: 500px) {
                margin-left: 0;
                font-size: 14px;
            }
        }
    }
    .text-btn{
        display: flex;
        align-items: center;
        span{
            display: inline-block;
            margin-right: 10px;
            color: #8c8b8b;
            font-size: 17px;
            font-weight: 500;
            text-transform: initial;
            transition: color 0.25s ease;
            @include breakpoint('xs'){
                margin-right: 4px;
                font-size: 14px;
            }
        }
    }

    &:hover{
        .text-btn{
            span{
                transition: color 0.25s ease;
                color: $requiredColor;
            }
        }
    }
}

.upload-complete {
    cursor: initial;
    font-weight: 500;
    border-bottom-color: $completeBorderColour!important;
    @extend .successful-dashboard;

    .staff &{
        .text-btn {
            i {
                @include anim(all linear .3s);
            }
            &:hover{
                text-decoration: none;
                i{
                    transform: translateY(5px);
                }
            }
        }
    }
}

.in-progress-dashboard {
    .staff & {
        cursor: default;
    }
}

.list-links{
    .text-btn{
        span{
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            color: #8c8b8b;
            font-size: 17px;
            font-weight: 500;
            text-transform: initial;
            transition: color 0.25s ease;
            @include breakpoint('xs'){
                margin-right: 4px;
                font-size: 14px;
            }
        }
    }
}

.message-blc{
    text-align: right;
    margin-top: 40px;
    .message-btn{
        font-size: 24px!important;
        letter-spacing: -0.24px!important;
        padding: 0 30px !important;
        @include breakpoint('xs'){
            padding: 0 20px !important;
        }
        &:hover{
            box-shadow: inset 0 -67px 0 0 #adbd04 !important;
        }
        i{
            font-size: 18px!important;
        }
    }
}

.article-container-user {
    .btn-cont {
        text-align: right;
        margin: 40px 0 0;
    }
}
.top-info {
    @include flexbox();
    @include alignItems(flex-start);
    @include justify(space-between);
    @include flex-wrap(wrap);
}
.app-progress {
    .in-progress {
        color: $inprogressColour;
        font-family: 'Bold';
    }
    .complete,
    .successful {
        color: $completeColour;
        font-family: 'Bold';
    }
    .form_change_status {
        display: inline-flex;
        @include alignItems(stretch);
        margin: 0 0 0 10px;
        @include breakpoint('xs-sml'){
            margin: 10px 0 0 0;
            width: 100%;
        }
        input[type="submit"] {
            border-width: 10px !important;
            height: 37px !important;
            line-height: 37px !important;
            font-size: 100% !important;
            border-bottom: 0 !important;
            transition: box-shadow 0.25s ease;
            &:hover {
                box-shadow: inset 0 -37px 0 0 #adbd04 !important;
            }
        }
    }
}
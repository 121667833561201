.datepicker-dropdown {
  background: $prmColor;
  padding: 0;
  box-shadow: none;
  margin: 0;
  border: none;
  border-radius: 0px;
  .datepicker-days, .datepicker-months, .datepicker-years {
    padding: 15px 19px;
  }
  .datepicker-months, .datepicker-years {
    th, td{
      background: transparent;
      border-color: transparent;
      padding: 0 !important;
      border-radius: 0!important;
      &:hover{
        background: transparent!important;
      }
    }
  }
  .datepicker-switch{
    cursor: pointer;
  }
  table{
    border: none;
    background: transparent;
    font-size: 16px;
    .prev{
      font-size: 0;
      cursor: pointer;
      &:before{
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(45deg);
      }
    }
    .next{
      font-size: 0;
      cursor: pointer;
      text-align: right;
      &:before{
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg);
      }

    }
    tr{
      border-color: transparent;

    }
    th, td{
      background: transparent;
      border-color: transparent;
      padding: 11px 12px !important;
      border-radius: 50%;
    }
    td{
      color: $baseWhite;
      cursor: pointer;
      text-align: center;
      &:hover{
        background: rgba(#000, .2);
      }

      &.old, &.new{
        color: #eaeaea;
      }

    }
    .datepicker-title {
      padding: 0;
      display: none!important;
    }

    .datepicker-switch{
      font-size: 18px;
      text-align: center;
      padding-bottom: 19px;
    }
    .dow{

    }
    .active{
      background: #000;
      color: #fff;
    }
    &.table-condensed{
      margin: 0;
      table-layout: fixed!important;

    }
    tfoot{
      display: none!important;
    }
  }
}

.datepicker-wrap{
  .datepicker-dropdown{
    max-width: 380px!important;
    @media screen and (max-width: 1200px) {
      max-width: 304px!important;
    }
    @include breakpoint('sm-xs'){
      max-width: 430px!important;
    }

    &.nested {
      max-width: 350px!important;
      td {
        padding: 12px !important;
      }
      @media screen and (max-width: 1200px) {
        max-width: 275px !important;
        .datepicker-days {
          padding: 15px 8px;
        }
        td {
          padding: 8px !important;
        }
      }
      @include breakpoint('sm-xs'){
        max-width: 370px!important;
      }
    }
  }
  .datepicker-dropdown {
    td{
      padding: 14px 12px !important;

      .month, .year {
        width: 33%;
        padding: 15px;
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }
}

.z_registration_page{
  .datepicker-dropdown {
    max-width: 350px!important;
    td {
      padding: 12px !important;
    }
    @media screen and (max-width: 1200px) {
      max-width: 275px !important;
      .datepicker-days {
        padding: 15px 8px;
      }
      td {
        padding: 8px !important;
      }
    }
    @include breakpoint('sm-xs'){
      max-width: 370px!important;
    }


  }

}
header {
  .header-top {
    a.top-btn.courses-btn {
      @include breakpoint('xs-sml') {
        margin-right: 10px;
      }
    }
    a.top-btn.signin-btn.login-btn {
      color: #fff;
      font-family: 'Bold';
      @include breakpoint('xs-sml') {
        font-size: 25px;
        padding: 0 10px;
      }
      .txt {
        @include breakpoint('xs-sml') {
          display: none;
        }
      }
      .fa-user {
        display: none;
        @include breakpoint('xs-sml') {
          display: inline-block;
          padding: 0;
        }
      }
    }
    .dropdown.top-btn {
      float: right;
      margin-top: 17px;
      margin-right: 15px;
      @include breakpoint('lg') {
        margin-top: 30px;
        margin-left: 15px;
        margin-right: 0;
      }
      @include breakpoint('md') {
        margin-top: 25px;
        margin-left: 15px;
        margin-right: 0;
      }
      @include breakpoint('sm') {
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 0;
      }
      @include breakpoint('xs-sml') {
        margin-right: 10px;
      }
      .btn {
        @include breakpoint('md') {
          height: 60px !important;
        }
        @include breakpoint('xs') {
          height: 41px !important;
          line-height: 36px !important;
        }
        @include breakpoint('xs-sml') {
          font-size: 25px !important;
          padding: 0 10px !important;
        }
        .fa-user {
          padding-left: 0;
          padding-right: 10px;
          @include breakpoint('xs') {
            padding-right: 5px;
          }
          @include breakpoint('xs-sml') {
            padding-right: 0;
          }
        }
        &:hover {
          box-shadow: inset 0 -67px 0 0 #adbd04!important;
        }
        .txt {
          @include breakpoint('xs-sml') {
            display: none;
          }
        }
      }
      .dropdown-menu {
        margin: 0;
        padding: 0 0 8px;
        border-radius: 0;
        border: 0;
        background-color: #adbd04;
        min-width: 216px;
        @include breakpoint('md') {
          min-width: 207px;
        }
        @include breakpoint('sm') {
          min-width: 156px;
        }
        @include breakpoint('xs') {
          min-width: 150px;
        }
        @include breakpoint('xs-sml') {
          left: auto;
          right: 0;
          margin: 2px 0 0;
          padding: 8px 0 8px;
          min-width: 143px;
        }
        a {
          color: #fff;
          display: block;
          padding: 13px 20px 13px;
          font: normal 130%/1 'Bold';
          &:hover {
            box-shadow: none !important;
          }
          @include breakpoint('sm-xs') {
            font: normal 110%/1 'Bold' !important;
            padding: 8px 20px 8px;
          }
          @include breakpoint('xs-sml') {
            padding: 8px 15px 8px;
          }
        }
      }
    }
  }
}
.registration{
  background-color: #eeeeee;
  padding: 42px 30px;
  .title_field{
    display: flex;
    flex-direction: column;

  }


  &.login{
    background: transparent;
    padding: 0;
    border-bottom: none!important;
    .form-group{
      input, select{
        background-color: #eeeeee;
        height: 70px;
        font-size: 19px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.28px;
        padding: 5px 30px;
        @include breakpoint('xs'){
          height: 60px;
        }
      }
      .required{
        top: 33px;
        @include breakpoint('xs'){
          left: -9px;
        }
      }


    }

  }
  label.error{
    color: $requiredColor;
    display: block;
    margin-top: 5px;
    font-weight: 700;
  }
  &-title-blc{
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    font-family:'Bold';
    @include breakpoint('xs'){
      flex-direction: column;
      align-items: flex-start;
    }
    h5{
      font-size: 20px;
      line-height: 22px;
    }

    .required-label{
      color: $requiredColor;
      font-size: 16px;
      line-height: 22.02px;
      margin-left: 70px;
      &:before{
        top: 10px!important;
      }
      @include breakpoint('xs'){
        margin-left: 0;
        margin-top: 8px;
      }
    }

  }

  .req-icon{
    position: relative;
    padding-left: 20px;
    &:before{
      content: "*";
      font-family:'Bold';
      position: absolute;
      top: 24px;
      left: 7px;
      color: $requiredColor;
      font-size: 19px;
      line-height: 8px;
      font-weight: 700;
    }
  }


  .blc-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    .radio-holder {
      &.gender_field {
        @include flexbox();
        @include alignItems(center);
        flex: 0 0 calc(50% - 15px);
        max-width: calc(50% - 15px);
        width: calc(50% - 15px);
        @include breakpoint('sm-xs') {
          flex: 0 0 100%;
          width: 100%;
          max-width: 100%;
        }
        .form-check {
          margin-bottom: 0;
          @include breakpoint($max: 380px) {
            margin-left: 15px;
          }
          label.error {
            position: absolute;
            top: 32px;
            left: 0;
            margin: 0;
          }
        }
        .form-check-label {
          font-family: 'Light';
          font-weight: 400;
          cursor: pointer;
          @include flexbox();
          @include alignItems(center);
          input {
            opacity: 1;
            margin-right: 10px;
            background: transparent;
            @include breakpoint($max: 380px) {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .form-group{
    margin: 0 0 20px 0;
    position: relative;
    .required{
      position: absolute;
      top: 20px;
      left: -12px;
      color: $requiredColor;
      font-family:'Bold';
      font-size: 19px;
      line-height: 8px;
      font-weight: 700;

    }
    input, select{
      background: $baseWhite;
      font-size: 16px;
      font-weight: 300;
      padding: 5px 13px;
      border-radius: 0;
      height: 40px;
      color: #595959;
      border: none;
      outline: none!important;
      &:focus{
        box-shadow: none;
        outline: none!important;
      }
    }
    .btn-group {
      &.open{
        button{
          .caret{
            transform: rotate(180deg);
          }
          }
        }
      }

    button{
      &.dropdown-toggle{
        background: $baseWhite!important;
        border: none!important;
        line-height: 1.25!important;
        height: 40px!important;
        color: #595959!important;
        font-size: 16px!important;
        padding: 5px 13px!important;
        font-weight: 400!important;
        font-family: 'Light'!important;
        outline: none!important;
      }
      .caret{
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }

    .dropdown-menu{
      padding: 0;
      border: none;
      li{
        &:hover{
          background: #eeeeee;
        }
        a{
          width: 100%;
          padding: 8px 15px;
          span{
            font-family: 'Light';
            font-size: 16px;
          }
          &:hover{
            background: #eeeeee;
          }
        }
      }
    }

    .select2 {
      &-selection {
        background: $baseWhite!important;
        border: none!important;
        line-height: 1.25!important;
        height: 40px!important;
        color: #595959!important;
        font-size: 16px!important;
        padding: 5px 13px!important;
        font-weight: 400!important;
        font-family: 'Light'!important;
        outline: none!important;
        border-radius: 0;
        &__rendered {
          padding-left: 0 !important;
        }
      }
      .select2-selection__arrow {
        height: 100%;
        b {
          border-top: 5px solid;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          position: absolute;
          top: 50%;
          right: 12px;
          margin-top: -2px;
          vertical-align: middle;
          left: auto;
          margin-left: 0;
        }
      }
    }

    &.half-input{
      flex: 0 0 calc(50% - 15px);
      max-width: calc(50% - 15px);
      width: calc(50% - 15px);
      @include breakpoint('sm-xs'){
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
    &.full-input{
      flex: 0 0 100%;
    }
  }

  .form-check{
    margin-left: 23px;
    margin-bottom: 10px;
    input[type=checkbox]{
      display: none;
    }
    label.error{
      margin-left: 7px;
    }

    &.journal_field,
    &.diary_field,
    &.newsletter_field,
    &.correspondence_field {
      min-width: 180px;
      margin-left: 0;
      label {
        margin-left: 0;
      }
    }
  }

  .btn-holder{
    margin-top: 40px;
    text-align: right;

    .button{
      &:not(.disabled-btn){
        background: $btnDarkBorder!important;
        border-color: #cbcbca!important;
        &:hover{
          box-shadow: inset 0 -67px 0 0 #cbcbca !important;
        }
      }
      font-size: 24px!important;
      &.disabled-btn {
        &:disabled {
          background: $btnDarkBorder!important;
          border-color: #cbcbca!important;
          &:hover{
            box-shadow: none!important;
          }
        }
        &:hover{
          box-shadow: inset 0 -67px 0 0 #adbd04 !important;
        }
      }
    }
  }

  .password_field {
    position: relative;
    &:after {
      content: attr(data-tooltip);
      text-transform: none;
      font-size: 14px;
      position: absolute;
      color: #000;
      background: #fff;
      border: 1px solid #000;
      padding: 8px 12px;
      width: max-content;
      opacity: 0;
      pointer-events: none;
      left: 50%;
      bottom: 0;
      border-radius: 4px;
      transform: translate(-50%,0%);
      transition: all .3s ease;
      transition-delay: .2s;
      z-index: 9;
      max-width: 245px;
      @include breakpoint('xs'){
        display: none;
      }
    }
    &:hover {
      &:after {
        opacity: 1;
        transform: translate(-50%, calc(100%));
        @media screen and (max-width: 560px){
          transform: translate(-50%, calc(-100%));
        }
      }
    }
  }

}

.sub-nav-upload {
  margin-bottom: 30px;
  background: #fff;
  padding: 0 0 40px;
  text-align: center;
  .img-bg {
    padding: 40px;
    img.uploadImg {
      max-width: 100%;
    }
    .cr-boundary {
      width: 100% !important;
    }
  }
  .add-img {
    .d-none {
      display: none;
    }
    .add-img-txt {
      cursor: pointer;
    }
    label {
      &:hover {
        color: $baseRedColor;
        cursor: pointer;
      }
    }
  }

  &.croppie-init {
    .img-bg {
      padding: 0;
      .uploadImg {
        display: none;
      }
    }
  }
}

.account-form {
  .upload-input {
    display: none;
  }
}

.select2-container {
  .select2-dropdown {
    border: 0;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    .select2-results__option {
      padding: 8px 15px;
    }
  }
}
/* Variables */
$primaryColour: #c80b0f !default;
//$primaryColourDark: #005420 !default;
//
//$downloadsWidgetColour: #d10211 !default;
//$downloadsWidgetColourDark: #970710 !default;

$inprogressColour: #c80b0f;
$completeColour: #009639;
$completeBorderColour: #00712b;
$inreviewColour: #f68a0c;
$messageColour: #d2d700;


$primaryColourHover:darken($primaryColour,15%);

$baseWhite: #ffffff;
$baseBlack: #000000;


$textColour: $baseBlack;
$borderColour: $baseBlack;
$borderWidth:2px;
$boxBackground:#f3f3f3;

$textDark: $baseBlack;
$boxBackgroundDark: $baseBlack;
$tabsBg: #f5f5f5;
$textOnPrimaryColour:#fff;
$backgroundColour:#fff;
$widgetBackgrounGray: #f5f5f5;

$mainWidgetPadding: 40px;
$mainWidgetPaddingMobile: 25px;
$sidebarWidgetPadding: 25px;
$sidebarWidgetPaddingMobile: 25px;
$gutterWidth:40px;
$gutterWidthMobile:20px;

$borderWidth: 2px;

$baseRedColor: #c80b0f;
$baseGrey: #efefef;

$inputColor: #595959;

$btnDarkBackground: $baseGrey;
$btnDarkBorder: darken($baseGrey, 8.1%);
$btnDarkColor: #757273;
$requiredColor: #c80b0f;
$prmColor: #c9d739;
.widget-button{
  margin-bottom: 30px;
  width: 100%;
  padding: 0;
  height: 103px;
  @include breakpoint('sm-xs'){
    height: 87px;
    margin-bottom: 20px;
  }
  .btn{
    width: 100%;
    font-size: 30px !important;
    letter-spacing: -0.3px;
    padding: 18px 23px!important;
    height: auto!important;
    @include breakpoint('xs'){
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }
    &:hover{
      box-shadow: inset 0 -94px 0 0 #adbd04 !important;
    }
    i{
      padding-left: 6px;
      font-size: 20px;
    }
    &.fixed{
      position: fixed;
      z-index: 1000;
      top: 0;
      max-width: 270px;
      @include breakpoint('md-xs'){
        max-width: 220px;
      }
      @include breakpoint('sm-xs'){
        max-width: 230px;
      }
      @include breakpoint('xs'){
        top: inherit;
        max-width: 100%;
      }
    }
  }
}

header .header-top .top-btn {
  &.signin-btn {
    border-bottom: 6px solid #adbd04 !important;
    border-color: #adbd04 !important;
    background: #c9d739 !important;
    &:hover{
      box-shadow: inset 0 -67px 0 0 #adbd04 !important;
    }
  }
}

.buttons-widget{
  .btn{
    font-size: 17px!important;
    margin: 0 17px 18px 0;
    padding: 23px 31px 10px 31px !important;
    height: auto !important;
    line-height: 1.2!important;
    @include breakpoint('sm-xs'){
      margin: 0 10px 10px 0;
    }
    &__dark{
      color: $btnDarkColor!important;
      border-color: $btnDarkBorder!important;
      background: $btnDarkBackground!important;

      &.swipe-btn{
        &:hover{
          box-shadow: inset 0 -53px 0 0 $btnDarkBorder !important;
        }
      }
    }
  }
}



.btn-link{
  display: block;
  font-family:'Bold';
  &:hover{
    text-decoration: none;
    i{
      transform: translateX(-5px);
      @include anim(all linear .3s);
    }
  }
  &.white{
    background: $baseWhite;
    border-color: $btnDarkBorder;
    color: $textDark;
  }

  &-sidebar{
    width: 100%;
    text-align: left;
    margin-bottom: 30px!important;

    font-size: 30px!important;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.3px;
    padding: 40px;

    @include breakpoint('xs'){
      margin-top: 30px!important;
      display: none!important;
    }

    i{
      margin-right: 17px;
      @include anim(all linear .3s);
      font-size: 21px;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}


.header-login-btn{
  font-size: 24px!important;
  letter-spacing: -0.24px!important;
  padding: 0 24px 0 33px !important;

  @media (max-width: 1199px){
    border-width: 8px!important;
    height: 60px!important;
    line-height: 52px!important;
    padding: 0 22px!important;
    font-size: 22px!important;
  }

  @include breakpoint('sm-xs'){
    height: 50px!important;
    line-height: 46px!important;
    font-size: 20px!important;
  }

  @include breakpoint('xs'){
    width: 100%!important;
    height: 50px!important;
    margin-bottom: 5px!important;
    font-size: 135%!important;
    padding: 5px 20px!important;
    line-height: 39px !important;
    border-bottom-width: 6px !important;
    &:hover{
      font-size: 135%!important;
      padding: 5px 20px !important;
      height: 50px !important;
      line-height: 39px !important;
    }
  }

  @media screen and (min-width: 767px) {
    &:hover{
      padding: 0 24px 0 33px !important;
      font-size: 24px!important;
      box-shadow: inset 0 -67px 0 0 #adbd04 !important;
    }
  }


  i{
    font-size: 19px;
    margin-left: 8px;
  }
}

.hide-mobile{
  @include breakpoint('xs'){
    display: none!important;
  }
}
.show-mobile{
  display: none!important;
  @include breakpoint('xs'){
    display: block!important;
  }
}

.side-btn {
  .btn {
    font-size: 28px !important;
    padding: 18px 20px !important;
    @include breakpoint('md-xs'){
      font-size: 22px !important;
    }
    @include breakpoint('xs'){
      font-size: 28px !important;
    }
  }
}
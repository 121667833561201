.form-title-blc{
  background: $baseWhite;
  padding: 30px 40px 30px 43px;
  margin-bottom: 27px;
  border-bottom: 10px solid #e6e6e6;
  @include breakpoint('xs'){
    padding: 20px 30px;
  }

  .inner{
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: 'Bold';

    @include breakpoint('xs'){
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .name-form{
    color: #1d1d1b;
    font-size: 32px;
    font-family: 'Bold';
    font-weight: 500;
    @include breakpoint('xs'){
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .pages{
    color: $requiredColor;
    font-size: 17px;
    margin: 0;
    @include breakpoint('xs') {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.form-complete-message {
  background: $messageColour;
  padding: 30px 80px 30px 43px;
  margin-bottom: 27px;
  position: relative;
  @include breakpoint('xs'){
    padding: 20px 50px 20px 30px;
  }
  &:after {
    content: "\f00c";
    font: normal normal normal 35px/1 FontAwesome;
    color: $baseWhite;
    position: absolute;
    top: 50%;
    right: 40px;
    margin-top: -20px;
    height: 40px;
    width: 40px;
    @include breakpoint('xs'){
      font: normal normal normal 25px/1 FontAwesome;
      right: 20px;
      margin-top: -13px;
      height: 30px;
      width: 30px;
    }
  }
  h3{
    color: #1d1d1b;
    font-size: 18px;
    font-family: 'Bold';
    margin: 0 0 10px;
    font-weight: 500;
    @include breakpoint('xs'){
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  .link {
    font-size: 18px;
    color: $baseWhite;
    font-family: 'Bold';
    @include anim(all 0.25s ease);
    &:hover {
      color: #1d1d1b;
    }
    & > i {
      margin: 0 10px 0 0;
      font-size: 24px;
      font-weight: 700;
      vertical-align: text-bottom;
    }
  }
}

.main-form {
  input, textarea, button {
    &:disabled {
        cursor: inherit;
        opacity: 1!important;
    }
  }

  .z-validation{
    border-bottom: none!important;
  }

  .main-text{
    form{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .sub-title {
      margin-bottom: 25px;
      color: #1d1d1b;
      font-size: 20px;
      font-weight: 500;
    }
    .radio-holder{
      &.required{
        &:before{
          top: 11px;
        }
      }

      .form-holder{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

    }

    .dashboard-widget{

    }
  }

  .upload-holder{
    margin-top: 0!important;
    ul{
      margin: 0;
      li{
        position: relative;
        &.required{
          &:before{
            content: "*";
            position: absolute;
            top: 20px;
            left: -17px;
            color: $requiredColor;
            font-family: 'Bold';
            font-size: 19px;
            line-height: 8px;
            font-weight: 700;
            @include breakpoint('xs'){
              left: -13px;
            }
          }
        }
      }
    }
  }

  .line-divider-widget {
    margin-top: 10px!important;
  }
  select{
    background: #eeeeee;
    font-size: 16px;
    font-weight: 300;
    padding: 5px 15px;
    border: none;
    border-radius: 0px;
  }

  .header-blc{

    width: 100%;
    margin-bottom: 40px;
    &-title{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #000000;
      font-family: "Bold";
      font-size: 32px;
      @include breakpoint('xs'){
        font-size: 26px;
      }
    }
    p{
      color: #333333;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      word-wrap: break-word;
    }
  }

  .req-icon{
    position: relative;
    padding-left: 20px;
    color: #c80b0f;
    font-size: 16px;
    line-height: 22.02px;
    margin-left: 5px;
    &:before{
      content: "*";
      font-family:'Bold';
      position: absolute;
      top: 10px;
      left: 7px;
      color: $requiredColor;
      font-size: 19px;
      line-height: 8px;
      font-weight: 700;
    }
  }

  .accordion{
    margin-bottom: 40px;

    .radio-holder{
      &.required{
        &:before{
          top: 11px;
        }
      }
      .radio-check-list {

      }
    }

    .form-group{
      button{
        &.dropdown-toggle{
          background: $baseWhite!important;
          border: none!important;
          line-height: 34px!important;
          height: 40px!important;
          color: #595959!important;
          font-size: 16px!important;
          padding: 5px 13px!important;
          font-weight: 400!important;
          font-family: 'Light'!important;
          outline: none!important;
          &.disabled {
            opacity: 1!important;
          }
        }
        .caret{
          border-top: 5px solid;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          border-bottom: 0;
        }
      }
    }

      &-item{
        margin-top: 29px;
        &:first-child{
          margin-top: 0;
        }
      }


    &-title{
      background: $completeColour;
      border-bottom: 10px solid $completeBorderColour;

      display: flex;
      align-items: center;

      padding: 29px 24px 29px 29px;
      color: $baseWhite;
      .title-blc{
        flex: 1;
        color: #ffffff;
        font-size: 22px;
        font-weight: 500;
        margin-right: 15px;
        @media screen and (max-width: 600px){
          font-size: 16px;
        }
      }
      a{
        color: $baseWhite;
        text-decoration: none!important;
      }

      .edit{
        font-size: 17px;
        line-height: 25px;
        font-weight: 500;
        @media screen and (max-width: 600px){
          font-size: 0px;
        }
        i {
          font-size: 25px;
          line-height: 1;
          font-weight: 400;
          vertical-align: middle;
          margin-left: 5px;
          transition: all linear .3s;
          @media screen and (max-width: 600px) {
            font-size: 35px;
          }
        }

        &:hover{
          i{
            transform: translateX(5px);
            transition: all linear .3s;
          }
        }
      }
      .delete{
        font-size: 25px;
        margin-left: 4%;
        i{
          transition: all linear .3s;
        }
        &:hover{
          i{
            transform: scale(1.1);
            transition: all linear .3s;
          }

        }
      }
    }

    &-content{
      background-color: #eeeeee;

      padding: 29px 30px 40px;

      &_title{
        color: $baseRedColor;
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 19px;
      }
      &-holder{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      //.checkbox-cont{
      //  width: 100%!important;
      //}

      input, textarea{
        background: #fff;
        color: $inputColor;
      }
    }

    .btn-holder{
      text-align: right;
      .btn{
        font-size: 24px!important;
        &:hover {
          box-shadow: inset 0 -67px 0 0 #adbd04 !important;
        }
      }
    }
  }

  .radio-holder{

    &_title{
      color: #1d1d1b;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 28px;
    }

    .radio-check-title{
      margin-bottom: 13px;
      font-size: 17px;
      color: $baseRedColor;
      font-family: 'Bold';
      font-weight: normal;
    }

    .radio-check-list{
      @include flexbox();
      @include flex-wrap(wrap);
      @include alignItems(flex-start);
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        width: calc((100% / 3) - 50px);
        margin-bottom: 15px!important;
        margin-right: 50px;
        @include breakpoint('sm-xs'){
          width: calc((100% / 2) - 25px);
          margin-right: 25px;
          margin-bottom: 10px!important;
        }
        @media screen and (max-width: 600px){
          width: 100%;
          margin-right: 0;
        }
      }
    }

    .checkbox-cont{
      input[type='checkbox'],
      input[type='radio']{
        &:checked{
          & + label {
            &:after{
              opacity: 1;
            }
          }
        }

        & + label{
          &:hover{
            &:after{
              opacity: 0.5;
            }
          }
        }

      }

      input[type='checkbox']{
        & + label{
          background: transparent!important;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border: 3px solid #999999;
          }
          &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 7px;
            transform: rotate(-45deg);
            border-left: 3px solid #999999;
            border-bottom: 3px solid #999999;
            opacity: 0;
          }
        }
      }

      input[type='radio']{
        & + label{
          background: transparent!important;
          position: relative;
          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 3px solid #999999;
          }
          &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #999999;
            opacity: 0;
          }
        }
      }

    }

    .textarea-holder{
      textarea{
        min-height: 165px;
        background-color: #eeeeee;
        color: #595959;
        font-size: 16px;
        width: 100%;
        font-weight: 300;
      }
    }

  }

  .half{
    flex: 0 0 calc(50% - 15px);
    width: calc(50% - 15px);
    @include breakpoint('sm-xs'){
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .full{
    flex: 0 0 100%;
  }

  .warning{
    color: $baseRedColor;
    display: block;
    margin-top: 5px;
    font-weight: 700;
    font-size: 14px;
  }
  .btn-holder{
    text-align: right;
    font-size: 24px;
    width: 100%;
    margin-top: 20px;
    button{
      color: #757273!important;
      height: auto!important;
      font-size: 24px!important;
      font-weight: 500!important;
      line-height: 20px!important;
      letter-spacing: -0.24px!important;
      padding: 0!important;
      background: transparent!important;
      border: none!important;

      i{
        font-weight: 900;
        transition: all linear .3s;
      }

      &:hover{
        i{
          transform: translateX(5px);
          transition: all linear .3s;
        }
      }
    }
  }
  .form-group{
    margin-bottom: 20px;
    &.required{
      position: relative;
      &:before{
        content: "*";
        position: absolute;
        top: 20px;
        left: -17px;
        color: $baseRedColor;
        font-family: 'Bold';
        font-size: 19px;
        line-height: 8px;
        font-weight: 700;
        @include breakpoint('xs'){
          left: -13px;
        }
      }
    }
    .radio-check-title{
      margin-bottom: 10px;
      color: $baseRedColor;
      font-family: 'Bold';
      font-weight: normal;
    }

    button{
      &.dropdown-toggle{
        height: 40px !important;
        background: #eeeeee !important;
        font-size: 16px !important;
        font-family: "Light"!important;
        padding: 5px 15px !important;
        border: none !important;
        border-radius: 0px !important;
        border-bottom: none !important;
        line-height: initial!important;
        color: #595959 !important;
        font-weight: 200 !important;
        .filter-option{
          float: none!important;
        }
        &.disabled {
          opacity: 1!important;
        }
      }
      .caret{
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: 0;
      }
    }

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .radio-check-list{
      .checkbox-cont{
        padding-left: 23px;
        margin-bottom: 5px;
      }
      input[type=checkbox],
      input[type=radio]{
        appearance: none;
        width: 0;
        height: 0;
        &:disabled {
          & + label{
            cursor: inherit;
            &:hover{
              &:after{
                opacity: 0!important;
              }
            }

          }

          &:checked{
            & + label{
              cursor: inherit;
              &:hover{
                &:after{
                  opacity: 1!important;
                }
              }

            }
          }
        }
      }
    }

    .form-control{
      height: 40px;
    }


    .dropdown-menu{
      padding: 0;
      border: none;
      li{
        &:hover{
          background: #eeeeee;
        }
        a{
          width: 100%;
          padding: 8px 15px;
          span{
            font-family: 'Light';
            font-size: 16px;
          }
          &:hover{
            background: #eeeeee;
          }
        }
      }
    }
    .bootstrap-select{
      margin: 0!important;
    }
  }
  input, textarea {
    background: #eeeeee;
    font-size: 16px;
    font-weight: 300;
    padding: 5px 15px;
    border: none;
    border-radius: 0px;
    border-bottom: none!important;
    color: #595959;
  }
  input{
    height: 40px;
  }
  textarea{
    width: 100%;
    min-height: 165px;
    padding: 15px;
  }

  .forms-holder{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .header-blc{
      flex: 0 0 100%;
    }


    .form-group{
      .dropdown-toggle {
        background: #eeeeee!important;
        font-size: 16px;
        font-weight: 300;
        padding: 5px 15px;
        border: none;
        border-radius: 0px;
      }
    }


  }

}

.bottom-btns{
  margin-top: 40px;
  overflow: hidden;
  @media screen and (max-width: 540px){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  a{
    font-size: 24px!important;
    font-weight: 500!important;
    letter-spacing: -0.24px;
    min-width: 152px!important;
    @media screen and (max-width: 540px){
      min-width: 120px!important;
      margin: 0 0 10px 0;
      font-size: 16px!important;
    }
    &:hover{
      font-size: 24px!important;
      @media screen and (max-width: 540px){
        font-size: 16px!important;
      }
    }
  }
  .prev, .next{
    background-color: #009639!important;
    border-bottom-color: #00712b!important;
    @media screen and (max-width: 540px){
      flex: 1;
    }
    &:hover{
      box-shadow: inset 0 -67px 0 0 #00712b !important;

    }
  }
  .prev{
    i{
      margin-left: 0;
      margin-right: 10px;
    }
  }
  .next, .save{
    float: right;
  }
  .next{
    margin-left: 15px;
    &.remove-margin{
      @media screen and (max-width: 540px){
        margin-left: 0;
      }
    }
  }
  .save{
    @media screen and (max-width: 540px){
      width: 100%;
    }
    &:hover{
      box-shadow: inset 0 -67px 0 0 #adbd04 !important;
    }
  }
}

.form_change_status{
  input[type="submit"]{
    margin-left: 10px;
  }
}
.course-overview-widget {
  background: $btnDarkBackground;
  border-bottom: 10px solid;
  border-color: $btnDarkBorder;
  padding: 36px 112px 32px 59px;
  @include breakpoint('sm-xs'){
    padding: 36px;
  }

  h4{
    margin-bottom: 21px!important;
  }

  .columns{
    display: flex;
    justify-content: space-between;
    @include breakpoint('sm-xs'){
      flex-direction: column;
    }

    .column{
      flex: 1 1 calc(50% - 60px);
      box-sizing: content-box;
      @include breakpoint('sm-xs'){
        flex: 0 0 100%;
      }
      &-first {
        .column-item {
          &:last-child{
            @include breakpoint('sm-xs'){
              border-bottom: 1px solid $btnDarkBorder;
            }
          }
        }
      }
      &-second {
        margin-left: 43px;
        padding-left: 36px;
        border-left: 1px solid $btnDarkBorder;
        @include breakpoint('sm-xs'){
          padding-left: 0;
          margin: 0;
          border-left: none;
        }
      }
      &-item {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $btnDarkBorder;
        &:last-child{
          border-bottom-color: transparent;
          margin-bottom: 0;
          padding-bottom: 0;
          @include breakpoint('sm-xs'){
            padding-bottom: 10px;
            margin-bottom: 10px;
          }
        }
      }
      h6{
        color: #c80b0f;
        font-size: 17px;
        font-weight: 700;
      }
      p{
        margin-top: 6px;
        line-height: 1.4;
      }
    }
  }
}


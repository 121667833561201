/* Theme specific variables */
//$primaryColour:blue;
$downloadsWidgetColour: #35aee2;
$downloadsWidgetColourDark: #055a7f;

/* SASS guidelines

.element{
	// Normal element styles here e.g. position: relative etc.

	@include breakpoint for this element

	.inner-elements{
		// Styles for inner elements here
	}

	&.modifier {
		// Styles for modifier of element here
	}
}
*/

// Base, Mixins and variables
@import 'base/mixins',
		'base/variables';

//@import 'base/resets',
@import 'base/helpers',
		'base/base';

// Site Layout
@import 'layout/header';

// Site Components
//@import 'components/button',
//		'components/datepicker',
//		'components/forms',
//		'components/lists',
//		'components/pagination',
//		'components/sub-nav',
//		'components/spinner',
//		'components/typography',
//		'components/notification',
//		'components/fancybox',
//		'components/cookie',
//		'components/search',
//		'components/modal',
//		'components/news-events-submenu';


@import 'components/datepicker',
		'components/loader';
// Pages
@import 'pages/dashboard',
		'pages/registration',
		'pages/chat';


@import 'widgets/buttons',
		'widgets/form',
		'widgets/course-overview',
		'widgets/subnav',
		 'widgets/sidebar-info';

// Global Widget Styles
//@import 'widgets/widgets';

// Widgets


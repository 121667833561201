.application{
  .subnav{
    padding-top: 20px!important;
  }
  &.sub-nav{
    .link{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 30px 10px 25px;
      &:after{
        content: '+';
        font-size: 25px;
        line-height: 1;
        font-weight: 600;
        color: #cccccc;
      }
      &.expanded{
        &:after{
          content: '-';
        }
      }
      .fa{
        display: none!important;
      }
    }

    ul {
      li {
        & + li {
          border: none!important;
        }
      }
    }
  }
}

.form-title-blc{
  margin-top: 0!important;
}

.form-links {
  border-bottom: 10px solid #e6e6e6;
  position: relative;
  padding: 40px 40px 35px!important;
  @include breakpoint('sm-xs'){
    padding: 30px!important;
  }
  @include breakpoint('xs'){
    display: none;
  }

  .status-blc {
    position: absolute;
    bottom: 0-10px;
    left: 0;
    height: 10px;
    &.in-progress{
      background: $requiredColor;
    }
    &.complete {
      background: $completeColour;
    }
  }


  &_title {
    color: #1d1d1b;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 12px!important;
    padding: 0!important;
  }

  &_status {
    font-size: 17px;
    &.in-progress {
      color: $inprogressColour;
      font-weight: 700;
    }
    &.complete {
      color: $completeColour;
      font-weight: 700;
    }

  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 19px 0 0 0;
    &-item {
      padding: 10px 0;
      border-bottom: 1px solid #dadada;
      a{
        font-size: 17px;
        color: #666666;
        font-weight: 200;
        font-family: "light";
      }
      &:last-child{
        border-bottom: none;
      }

      &__title {
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: 10px;
          left: -40px;
          width: 15px;
          height: calc(100% - 20px);
          background: $inprogressColour;
          @include breakpoint('sm-xs'){
            left: -30px;
          }
        }
        a{
          font-weight: 500;
          color: $inprogressColour;
          font-family: "Bold";
        }

      }
      &__complete {
        position: relative;
        a{
          &:before{
            content: '';
            position: absolute;
            top: 16px;
            left: -19px;
            width: 9px;
            height: 6px;
            border-left: 2px solid #999999;
            border-bottom: 2px solid #999999;
            transform: rotate(-45deg);
          }
        }
      }
      &__text {

      }
    }
  }

  &.sub-nav{
    padding: 0!important;
    border-bottom: none!important;
    display: block;
    .form-links-list-item{
      &:before{
        left: -24px;
      }
      a{
        margin: 0!important;
        font-size: 14px;
        padding: 0 0 0 20px;
      }
      &__title{
        a{
          color: $requiredColor;
          font-weight: 600;
          font-family: "Bold";
        }
      }
      &__complete {
        a{
          &:before{
            content: '';
            position: absolute;
            top: 4px;
            left: 2px;
            width: 9px;
            height: 6px;
            border-left: 2px solid #999999;
            border-bottom: 2px solid #999999;
            transform: rotate(-45deg);
          }
        }
      }
    }
    .status-text{
      font-size: 14px;
    }

  }

  &-sub-header{
    position: relative;

    .text{
      color: #000000;
      font-size: 18px;
      font-weight: 700;
      i{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 21px;
        color: $requiredColor;
      }
    }
    &.expanded{
      .text{
        i{
          //transform: rotate(180deg);
        }
      }
    }
    .text-cont{
      color: $baseBlack;
      font-size: 14px;
    }
  }


  &.complete{
    .form-links-list-item__title {
      &:before{
        background: #009639;
      }
      a{
        color: #009639;
      }
    }
  }

}
section.main-body {
    .chat-widget {
        margin-top: 0;
    }
}

.chat-widget {
    .message_with {
        @include flexbox();
        @include alignItems(center);
        @include breakpoint('sm-xs') {
            @include flex-wrap(wrap);
        }
    }
    .img-cont {
        border-radius: 50%;
        overflow: hidden;
        width: 87px;
        height: 87px;
        background-size: contain;
        margin: 0 30px 0 0;
        background-image: url('../img/img-holder.png');
    }
    .text-cont {
        @include breakpoint('xs-sml') {
            margin: 20px 0 0;
            width: 100%;
        }
        .title{
            font-size: 32px;
            font-family: "Bold";
            @include breakpoint('sm-xs') {
                font-size: 25px;
            }
        }
        .name {
            font-size: 26px;
            color: $primaryColour;
            font-family: "Bold";
            @include breakpoint('sm-xs') {
                font-size: 20px;
            }
        }
    }
    .comments-form {
        background-color: $baseGrey;
        padding: 25px 32px;
        margin: 40px 0 40px;
        @include breakpoint('sm-xs') {
            padding: 25px 20px;
        }
        .form-title{
            font-size: 20px;
            font-family: "Bold";
            margin: 0 0 15px;
        }
        textarea {
            padding: 20px;
            width: 100%;
            @include breakpoint('sm-xs') {
                padding: 15px;
            }
        }
        .btn-cont {
            text-align: right;
            margin: 25px 0 0;
        }
        .form-submit {
            color: #fff;
            position: relative;
            padding: 0 !important;
            .submit {
                padding: 0 55px 0 28px !important;
                @include breakpoint('sm-xs') {
                    padding: 0 25px 0 25px !important;
                    width: 100%;
                }
                &.disabled-btn {
                    &:disabled {
                        background: $btnDarkBorder !important;
                        border-color: #cbcbca !important;
                        &:hover {
                            box-shadow: none !important;
                        }
                    }
                    &:hover {
                        box-shadow: inset 0 -67px 0 0 #adbd04 !important;
                    }
                }
            }
            i {
                position: absolute;
                top: calc( 50% - 5px );
                right: 25px;
                transform: translate(0, -50%);
                font-size: 130%;
                @include breakpoint('sm-xs') {
                    top: calc( 50% - 3px);
                    right: calc( 50% - 40px );
                }
            }
        }
        &+.comments_list {
            border-top: 1px solid #d9d9d9;
            padding: 30px 0 0;
        }
    }
    .right_side,
    .left_side {
        max-width: 71%;
        padding: 30px 33px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include breakpoint('sm-xs') {
            max-width: calc( 100% - 35px );
            padding: 20px 20px;
        }
        @include breakpoint('xs') {
            max-width: calc( 100% - 25px );
        }
    }
    .left_side {
        margin: 0 0 30px 0;
        background-color: $baseGrey;
        border-bottom: 10px solid #dadada;
        .comments_head {
            color: $primaryColour;
            .name {
                color: $primaryColour;
            }
        }
    }
    .right_side {
        margin: 0 0 30px auto;
        background-color: #d2d700;
        border-bottom: 10px solid #c5c827;
        .comments_head {
            color: #fff;
            .name {
                color: #fff;
            }
        }
    }
    .comments_head {
        width: 100%;
        margin: 0 0 20px;
        @include flexbox();
        @include alignItems(center);
        @include justify(space-between);
        .name {
            font-size: 16px;
        }
        .d-time {
            padding: 0 0 0 10px;
        }
    }
    .comments_body {
        width: 100%;
        font-size: 18px;
        @include breakpoint('sm-xs') {
            font-size: 15px;
        }
    }
}

.intro-text-widget {
    .btn-cont {
        text-align: right;
        margin: 25px 0 0;
        .submit {
            font-size: 24px !important;
            @include breakpoint('sm-xs') {
                width: 100%;
            }
            @include breakpoint('xs') {
                font-size: 18px !important;
            }
        }
    }
}
.main-text{
  & > div {
    // fix Staff dashboard
    &:last-child{
      margin-bottom: 0!important;
    }
  }
}

.custom-form{
  height: auto!important;
  z-index: 1!important;
}


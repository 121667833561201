.back {
	a {
		background: #fff;
		padding: 32px 42px;
		color: #000;
		font-size: 30px;
		font-family: "Bold";
		vertical-align: middle;
		@include flexbox();
		@include alignItems(center);
		& > i {
			font-size: 20px;
			padding-right: 10px;
		}
	}
}
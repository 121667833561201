.widget-info {
  @include breakpoint('xs'){
    display: none;
  }
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    &-item {
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 8px;
      margin-bottom: 8px;
      &:last-child{
        border-bottom: none;
      }
      &__title {
        color: #c80b0f;
        font-family: "Bold";
        font-weight: 600;
      }

      &__text {
        font-family: "Light";
        font-weight: 300;
        line-height: 25px;
        margin-top: 0;
      }

    }

  }
}